import clsx from "clsx";
import { useRouter } from "next/router";
import { useState } from "react";

const LandingIllustration = ({ active, values }) => {
  const { locale } = useRouter();
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={clsx(
        "absolute transition-all duration-300 inset-0 flex items-center justify-center",
        "lg:-top-24 lg:-bottom-32 lg:-right-5",
        // "bg-red-500",
        active ? "opacity-1" : "opacity-0 scale-75"
      )}
    >
      {!values?.id ? (
        <img
          src="https://cdn-sdotid.adg.id/assets/landing-default.svg"
          alt="Landing Default"
          className="w-full h-full object-contain"
          style={{ scale: "0.95" }}
        />
      ) : (
        <a
          href={values.url}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx("inline-block w-full h-full relative z-20", {
            "landing-animate-atention": values?.url?.length > 0,
          })}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          // style={{ scale: "0.95" }}
        >
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div
              className={clsx(
                "text-gray-900 bg-white py-2 px-3 rounded shadow-md z-10 transition-all",
                {
                  "translate-y-5 opacity-0": !isHover,
                }
              )}
            >
              {values?.title[locale || "en"]}
            </div>
          </div>
          {/* <img
            src={"https://cdn-sdotid.adg.id/assets/twibbon-campaign-2305.png"}
            alt="Image"
            className="object-contain w-full h-full"
          /> */}
          <img
            src={values.src}
            className="transition-all hover:scale-[1.02] w-full h-full object-contain"
            style={values.style}
            alt="Landing Default"
          />
        </a>
      )}
    </div>
  );
};

export default LandingIllustration;
