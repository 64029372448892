import {
  CubeIcon,
  CursorArrowRippleIcon,
  EyeDropperIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";

import Button from "@sid/core/components/common/Button";
import { Trans } from "next-i18next";
import Link from "next/link";
import InfoCard from "../InfoCard";

const LandingSection2A = () => {
  return (
    <Fragment>
      <h1 className="text-2xl md:text-5xl font-bold font-montserrat leading-tight text-white text-center mb-12 -mt-10 md:-mt-20">
        <Trans i18nKey="landing:whats_include">What&apos;s Includes?</Trans>
      </h1>

      <section className="flex flex-col md:flex-row gap-6 px-6 md:px-0">
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:click_to_tracks">Click to Tracks</Trans>
            }
            description={
              <Trans i18nKey="landing:click_to_tracks_description">
                Know how many times your links and button was clicked.
              </Trans>
            }
            icon={<CursorArrowRippleIcon />}
            iconClassName="text-blue-600"
            image="/images/landing/landing-microsite-1.png"
          />
        </div>
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:various_components">
                Various Components
              </Trans>
            }
            description={
              <Trans i18nKey="landing:various_components_description">
                Expand your microsite potential with various components.
              </Trans>
            }
            icon={<CubeIcon />}
            iconClassName="text-blue-600"
            image="/images/landing/landing-microsite-2.png"
          />
        </div>
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:fully_customized">Fully Customized</Trans>
            }
            description={
              <Trans i18nKey="landing:fully_customized_description">
                Customize your microsite to match your brand identity.
              </Trans>
            }
            icon={<EyeDropperIcon />}
            iconClassName="text-blue-600"
            image="/images/landing/landing-microsite-3.png"
          />
        </div>
      </section>

      <div className="w-full flex justify-center mt-20">
        <Link passHref href="/microsite">
          <Button
            color="blue"
            className="text-2xl font-semibold px-8 font-montserrat"
            roundFull
          >
            <Trans i18nKey="learn_more">Learn more</Trans>
          </Button>
        </Link>
      </div>
    </Fragment>
  );
};

export default LandingSection2A;
