import { assetPrefix } from "@sid/core/vars/assetUrl";

const AsFeatured = () => {
  const featureds = [
    {
      image: "rmid-logo.png",
      alt: "rm.id",
      url: "https://rm.id/baca-berita/ekonomi-bisnis/140570/hadir-di-acara-bergengsi-internasional-tahunan-icann-meeting-sid-memperkenalkan-url-shortener-dan-tool-bio-link",
    },
    {
      image: "tempo-co.png",
      alt: "tempo.co",
      url: "https://tekno.tempo.co/read/1624272/mengenal-platform-penyingkat-tautan-s-id-dan-cara-menggunakannya",
    },
    // {
    //   image: "medicom.png",
    //   alt: "medicom.id",
    //   url: "https://mediaindonesia.com/teknologi/515876/tiga-fitur-yang-jadi-keunggulan-sid",
    // },
    {
      image: "liputan6.png",
      alt: "liputan6.com",
      url: "https://www.liputan6.com/tekno/read/5045622/3-fitur-tersembunyi-di-platform-pemendek-tautan-sid",
    },
    {
      image: "detikinet.png",
      alt: "detikinet",
      url: "https://inet.detik.com/cyberlife/d-6245404/kenalan-platform-penyingkat-tautan-sid-dan-cara-menggunakannya",
    },
    {
      image: "merdeka-com.png",
      alt: "merdeka.com",
      url: "https://www.merdeka.com/teknologi/terdaftar-di-pse-kominfo-platform-penyingkatan-link-sid-punya-tiga-fitur-andalan.html",
    },
    {
      image: "antaranews.png",
      alt: "antaranews.com",
      url: "https://www.antaranews.com/berita/3066125/cara-mempersingkat-tautan-gratis",
    },
    {
      image: "sindonews.png",
      alt: "sindonews.com",
      url: "https://tekno.sindonews.com/read/782295/207/pembuatan-microsite-sid-2022-wadah-content-creator-berkreasi-1653753953",
    },
    {
      image: "media-indonesia.png",
      alt: "mediaindonesia.com",
      url: "https://mediaindonesia.com/teknologi/515876/tiga-fitur-yang-jadi-keunggulan-sid",
    },
  ];

  return (
    <div className="flex flex-wrap justify-center">
      {featureds.map((item, index) => (
        <div key={index} className="p-3 w-6/12 md:w-auto">
          <a
            href={item.url}
            className="transition-all hover:opacity-70"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={assetPrefix + `images/featured/${item.image}`}
              alt={item.alt}
            />
          </a>
        </div>
      ))}
    </div>
  );
};

export default AsFeatured;
